// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-about-page-js": () => import("./../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-category-entry-js": () => import("./../src/templates/category-entry.js" /* webpackChunkName: "component---src-templates-category-entry-js" */),
  "component---src-templates-video-entry-js": () => import("./../src/templates/video-entry.js" /* webpackChunkName: "component---src-templates-video-entry-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

